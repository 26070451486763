import request from "@/utils/http";

export function insServiceAn(params = {}) {
    return request({
        url: '/Planes/insServiceAn',
        method: 'post',
        data: params
    })
}
export function anPrice(params = {}) {
    return request({
        url: '/Planes/anPrice',
        method: 'post',
        data: params
    })
}
export function claimsOrder(params = {}) {
    return request({
        url: '/Planes/claimsOrder',
        method: 'post',
        data: params
    })
}
export function setPlanesAn(params = {}) {
    return request({
        url: '/Planes/setPlanesAn',
        method: 'post',
        data: params
    })
}
export function setPlanesApple(params = {}) {
    return request({
        url: '/Planes/setPlanesApple',
        method: 'post',
        data: params
    })
}
export function planesInfo(params = {}) {
    return request({
        url: '/Planes/planesInfo',
        method: 'post',
        data: params
    })
}
export function setPlanesNnhx(params = {}) {
    return request({
        url: '/Planes/setPlanesNnhx',
        method: 'post',
        data: params
    })
}
