<template>
  <order-detail-wrapper :status="orderData.exa_status">
    <section-title>换机信息</section-title>
    <van-cell title="新IMEI或序列号" :value="orderData.imei"></van-cell>
    <van-cell title="旧IMEI或序列号" :value="orderData.old_imei"></van-cell>
    <van-cell title="品牌" :value="orderData.brand_name"></van-cell>
    <van-cell title="机型" :value="orderData.model_name"></van-cell>
    <van-cell title="手机内存" :value="orderData.ram_size"></van-cell>
    <template>
      <van-cell title="新机协议价格" :value="orderData.new_price"></van-cell>
      <van-cell title="旧机协议价格" :value="orderData.old_price"></van-cell>
      <van-cell title="补交金额" :value="orderData.money"></van-cell>
      <van-cell title="理赔金额" :value="orderData.claims_money"></van-cell>
    </template>
    <van-cell
      title="销单号/订单号"
      :value="orderData.odd_num || '--'"
    ></van-cell>
    <section-title>换机完成照片</section-title>
    <img-display2to1 :src="orderData.tem_img"></img-display2to1>
    <section-title>订单信息</section-title>
    <notice>
      <div class="notice-item">
        <div class="notice-name">维修编号</div>
        <div class="notice-value">{{ orderData.finish_sn }}</div>
      </div>
      <div class="notice-item">
        <div class="notice-name">提交时间</div>
        <div class="notice-value">{{ orderData.add_time }}</div>
      </div>
      <template v-if="orderData.exa_status === 1">
        <div class="notice-item">
          <div class="notice-name">审核通过时间</div>
          <div class="notice-value">{{ orderData.exa_time }}</div>
        </div>
        <div class="notice-item">
          <div class="notice-name">商家审核内容</div>
          <div class="notice-value">{{ orderData.fail_exp }}</div>
        </div>
      </template>
      <template v-if="orderData.exa_status === 2">
        <div class="notice-item">
          <div class="notice-name">未通过原因</div>
          <div class="notice-value">{{ orderData.fail_exp }}</div>
        </div>
      </template>
    </notice>
    <my-button class="edit-btn" v-if="orderData.exa_status === 2" @click="edit"
      >编辑订单</my-button
    >
  </order-detail-wrapper>
</template>

<script>
import { planesInfo } from "@/api/changeComplete";

export default {
  name: "ChangeCompleteDetail",
  props: {
    id: String,
  },
  data() {
    return {
      orderData: {},
    };
  },
  mounted() {
    planesInfo({ finish_id: this.id }).then((res) => {
      this.orderData = res.data;
    });
  },
  methods: {
    edit() {
      let type;
      switch (this.orderData.planes_type) {
        case 1:
          type = "iphoneYearChange";
          break;
        case 2:
          type = "androidYearChange";
          break;
        case 3:
          type = "iphone";
          break;
        case 4:
          type = "android";
          break;
        case 5:
          type = "zhixuanhuanji";
          break;
        case 6:
          type = "upServer";
          break;
        case 7:
          type = "iphone";
          break;
      }
      this.$router.push({
        name: "ChangeCompleted",
        params: {
          type,
          id: this.orderData.claims_id,
        },
        query: {
          finish_id: this.id,
          imei: this.orderData.imei,
          tem_img: this.orderData.tem_img,
          odd_num: this.orderData.odd_num,
          ins_service_name: this.orderData.ins_service_name,
          money: this.orderData.money,
          price: this.orderData.new_price,
        },
      });
    },
  },
};
</script>

<style scoped>
.notice-item {
  display: flex;
  line-height: 60px;
}

.notice-name {
  color: #666;
  min-width: 220px;
  white-space: nowrap;
}

.notice-value {
  word-break: break-all;
}
.edit-btn {
  width: calc(100vw - 150px);
  margin: 100px auto 0;
}
</style>
